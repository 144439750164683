@import '/node_modules/sass-rem/_rem.scss';
@import '/node_modules/@appkit4/styles/scss/variables';

html,
body {
  padding: 0;
  margin: 0;
  font-family: $font-family;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5rem;
  color: var(--color-text-body);
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

button {
  outline: none;
  cursor: pointer;
}

*:focus {
  outline: none;
}

body {
  background-repeat: no-repeat;
  background-attachment: fixed;
  // background-color: map-get($colors, grey-12);
  background-color: $color-background-default;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: $color-text-link-primary;
  text-decoration: none;
  cursor: pointer;
}

a:not([disabled='true']):not(.disabled):hover {
  color: $color-text-link-primary;
  text-decoration: underline;
}

a[disabled='true'],
a.disabled {
  pointer-events: none;
}

.dashboard-data {
  font-size: 4rem;
  line-height: 3rem;
}

.tooltip {
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.display-hide {
  display: none;
}

.visibility-hide {
  visibility: hidden;
}

.margin-b {
  margin-bottom: 1.25rem;
}

.container {
  margin: 0 auto;
  max-width: 77rem;
  position: relative;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-normal {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-spacer {
  flex-grow: 1;
  flex-shrink: 1;
}

.flex-justify-start {
  justify-content: start;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-center {
  justify-content: center;
}

.full-width {
  width: 100%;
}

.max-full-width {
  max-width: 100%;
}

.half-width {
  width: 50%;
}

.full-height {
  height: 100%;
}

.full-vh {
  height: 100vh;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.flex-gap-2,
.grid-gap-2 {
  gap: $spacing-2;
}

.flex-gap-3,
.grid-gap-3 {
  gap: $spacing-3;
}

.flex-gap-4,
.grid-gap-4 {
  gap: $spacing-4;
}

.flex-gap-5,
.grid-gap-5 {
  gap: $spacing-5;
}

.flex-gap-6,
.grid-gap-6 {
  gap: $spacing-6;
}

.flex-gap-7,
.grid-gap-7 {
  gap: $spacing-7;
}

.flex-gap-8,
.grid-gap-8 {
  gap: $spacing-8;
}

.flex-gap-7-5,
.grid-gap-7-5 {
  gap: calc($spacing-5 * 2); // 32px
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-auto {
  flex: 1 0 auto;
}

.form-field-flex {
  display: flex;
  flex-direction: column;
  gap: $spacing-5;
}

.text-wrap-ellipsis {
  @include text-ellipsis();
  width: 100%;
}

.box-margin-flex {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.item-margin-even {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  flex: 1;
}

.search-container {
  display: inline-block;
}

.menue-cdk-container {
  z-index: 1 !important;
}

.display-block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.vertical-align-middle {
  vertical-align: middle;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer-disabled {
  cursor: not-allowed;
}

.cursor-pointer[disabled='true'] {
  pointer-events: none;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.break-word {
  word-break: break-word;
}

.break-all-word {
  word-break: break-all;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.page-margin-bottom {
  margin-bottom: 4.125rem;
}

.white-space-nowrap {
  white-space: nowrap;
}

.break-word {
  overflow-wrap: break-word;
}

@for $i from 1 through 10 {
  .text-wrap-#{$i} {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}

.field-gap {
  margin-bottom: 24px;
}

// For grid setting

.grid {
  display: grid;
}

.grid-align-center {
  align-self: center;
}

.grid-align-baseline {
  align-self: baseline;
}

.grid-place-center {
  place-self: center;
}

.icon-position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@for $i from 1 through 5 {
  .grid-columns-#{$i} {
    display: grid;
    grid-template-columns: repeat($i, 1fr);
  }
}

@for $i from 1 through 5 {
  .grid-columns-auto-#{$i} {
    grid-template-columns: repeat(auto-fit, minmax(100px * $i, 1fr));
  }
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.no-border-radius {
  border-radius: 0 !important;
}

ul.no-list-style-type > li {
  list-style-type: none;
}

.file-link {
  cursor: pointer;
  text-decoration: underline;
  color: $color-text-link-primary;
}

.text-decoration {
  text-decoration: underline;
}

.overflow-auto {
  overflow: auto;
}

.under-line {
  border-bottom: $border-1 solid $color-background-border;
}

.table-cell-link {
  color: $color-text-body;
  font: $typography-body;
  font-weight: $font-weight-2;
}

.letter-spacing-1 {
  letter-spacing: $letter-spacing-1;
}

.position-bottom {
  position: fixed;
  bottom: 0;
}

.margin-top-auto {
  margin-top: auto;
}

.pointer-events-none {
  pointer-events: none;
}

.max-height-overflow {
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

.full-max-height {
  max-height: 100%;
}

.full-height-overflow {
  overflow: auto;
  max-height: 100%;
}

ul.no-default-list-style {
  list-style-type: none;
  padding-inline-start: 0;
  margin-block-start: 0;
}

.span-1 {
  grid-column: span 1;
}

.span-2 {
  grid-column: span 2;
}

.margin-left-native {
  margin-left: calc(-1 * $spacing-2);
}

.full-height-vh {
  max-height: 100vh;
}
.fixed-condensed-width {
  width: calc(100% - $width-542);
}

.fixed-report-condensed-width {
  width: calc(100% - $width-600 - $width-3);
}
