.none-content {
  margin: 4.375rem 0 7.875rem;

  @media (max-width: $screen-sm-max) {
    margin-top: 2.5rem;
    margin-bottom: 1.25rem;
  }

  &-text {
    margin-top: 1rem;
    display: inline-block;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    color: map-get($colors, grey-7);

    @media (max-width: $screen-sm-max) {
      padding: 0 15%;
    }
  }

  &-loading.loading {
    margin-top: 2rem;
    width: 8rem;

    @media (max-width: $screen-sm-max) {
      margin-top: 1rem;
    }
  }
}

.symbol-bar-wrapper {
  display: flex;
  justify-content: center;

  .icon-v-bars,
  .icon-h-bars {

    .path1,
    .path2 {
      &::before {
        font-size: 4rem;
      }
    }
  }

  .icon-pie-chart,
  .icon-table-data,
  .icon-spark-line {
    &::before {
      font-size: 4rem;
    }
  }
}
