.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

.visible-sm-block {
  @media (max-width: $screen-sm-max) {
    display: block !important;
  }
}
.visible-sm-inline {
  @media (max-width: $screen-sm-max) {
    display: inline !important;
  }
}
.visible-sm-inline-block {
  @media (max-width: $screen-sm-max) {
    display: inline-block !important;
  }
}

.visible-md-block {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    display: block !important;
  }
}
.visible-md-inline {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    display: inline !important;
  }
}
.visible-md-inline-block {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    display: inline-block !important;
  }
}

.visible-lg-block {
  @media (min-width: $screen-lg-min) {
    display: block !important;
  }
}
.visible-lg-inline {
  @media (min-width: $screen-lg-min) {
    display: inline !important;
  }
}
.visible-lg-inline-block {
  @media (min-width: $screen-lg-min) {
    display: inline-block !important;
  }
}

.hidden-sm {
  @media (max-width: $screen-sm-max) {
    display: none;
  }
}
.hidden-md {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    display: none;
  }
}
.hidden-lg {
  @media (min-width: $screen-lg-min) {
    display: none;
  }
}
