@import '/node_modules/sass-rem/_rem.scss';
@import '/node_modules/@appkit4/styles/scss/variables';

.ck.ck-balloon-panel.ck-balloon-panel_visible {
  z-index: 2001;
}

.mention {
  color: $color-text-link-primary;
}

.write-container {
  width: 100%;
  margin-top: $spacing-5;

  .ap-text-editor-container {
    box-shadow: none;
    border: 0;

    .ck-editor .ck-toolbar {
      display: none;
    }

    .ck-editor .ck.ck-editor__editable_inline {
      height: $height-112;
      border: $border-1 $border-type-1 $color-background-border;
      border-radius: $border-radius-2;
      padding-left: $spacing-3;
      padding-top: $spacing-5;
      scroll-padding-top: $spacing-5;
      line-height: 1.25rem;
      overflow-y: scroll;
      color: var(--color-text-heading) !important;

      .mention {
        color: $color-text-link-primary;
        background: none;
      }
    }

    .ck-editor .ck.ck-editor__editable_inline:hover {
      background-color: var(--color-background-hover);
    }

    .ck-content:focus {
      box-shadow: inset 0 0 0 .0625rem var(--color-background-primary), 0 0 0 .25rem rgba(var(--color-background-primary-rgb), var(--opacity-3)) !important;
      border: none !important;
    }

    .ck-content::after {
      content: "";
      position: absolute;
      right: .1875rem;
      bottom: .1875rem;
      width: .1875rem;
      height: .1875rem;
      border-radius: 50%;
      background-color: var(--color-background-border);
      transition: background-color .3s, opacity .3s;
      pointer-events: none;
    }
  }

  &.focus .ap-text-editor-container .ck-content::after {
    background-color: var(--color-background-primary);
  }

}

.ck.ck-reset.ck-list.ck-mentions .ck-list__item .edit-list-container .list-content .userinfo-box {
  overflow: hidden;
  max-width: 13rem;
  * {
     overflow: hidden;
     text-overflow: ellipsis;
  }
}