@mixin text-ellipsis() {
  @content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin database-scrollable($margin-between-viewport: 24rem) {
  @content;
  max-height: calc(100vh - $margin-between-viewport) !important;
  overflow-y: auto;
  overflow-x: hidden;
}
