@import '/node_modules/sass-rem/_rem.scss';
@import '/node_modules/@appkit4/styles/scss/variables';

.ag-row.row-activated {
  background-color: rgba($data-yellow, $opacity-2);
}

.ag-theme-quartz {
  --ag-foreground-color: var(--color-text-body);
  --ag-background-color: var(--color-background-container);
  --ag-header-foreground-color: var(--color-text-heading);
  --ag-header-background-color: var(--color-background-container);
  --ag-active-color: $color-background-container;
  --ag-row-border-color: var(--color-background-border);
  --ag-border-color: var(--color-background-border);
  --ag-wrapper-border-radius: var(--border-radius-3);
  --ag-font-size: 1rem;
  --ag-font-family: 'PwC Helvetica Neue', Helvetica, Arial, 'Microsoft Yahei',
    sans-serif, 'Segoe UI Emoji', 'Emoji';
  --ag-row-height: 48px;
  --ag-header-height: 48px;
  --ag-selected-row-background-color: rgba($data-yellow, $opacity-2);
  --ag-row-hover-color: rgba(255, 182, 0, 0.08);
  --ag-header-column-resize-handle-width: 1px;
  --ag-header-column-resize-handle-height: 50%;

  // --ag-list-item-height: 4.5rem;
}

.ag-pinned-right-header {
  border-left: none;
}

.ag-pinned-left-header {
  border-right: none;
}

.ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(
    .ag-cell-range-single-cell
  ) {
  border-left: none;
  text-align: right;
}

.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
    .ag-cell-range-single-cell
  ) {
  border-right: none;
  text-align: left;
}

.ag-root-wrapper {
  padding: 0 $spacing-4;
}

.ag-theme-quartz:not(.permission-ag-grid)
  .ag-layout-auto-height
  .ag-center-cols-viewport {
  min-height: 2 * $width-280;
}

.permission-ag-grid .ag-layout-auto-height .ag-center-cols-viewport {
  min-height: 0;
}

// For quick link table header
.quick-link-header-left-margin {
  margin-left: rem(30px);
}

.ag-theme-quartz .ag-dnd-ghost-icon {
  .ag-icon-arrows::before {
    content: '\f117';
  }
}

// For activity log
.full-width.ap-option-item .ap-option-desc {
  max-width: 100%;
}

.ap-grid
  .no-padding.ap-search-container
  .ap-search-list
  .ap-option-item
  .ap-option-label {
  padding-right: 0;
}

.ag-checkbox-input-wrapper {
  &::after {
    color: var(--color-text-body);
  }

  &.ag-disabled input {
    cursor: not-allowed;
  }

  &.ag-checked::after,
  &.ag-indeterminate::after {
    color: var(--color-background-icon-primary);
  }
}

.ap-filter-body-wrapper {
  padding: var(--ag-widget-container-vertical-padding)
    var(--ag-widget-container-horizontal-padding);
}

div[aria-sort='none']:hover {
  .ag-sort-indicator-icon.ag-sort-none-icon {
    display: block !important;
  }
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected) {
  border: none;
  outline: $color-background-primary auto 1px;
}

.ag-header-cell.ag-header-active::after {
  border: none;
  outline: $color-background-primary auto 1px;
}

.flex-end .ag-header-cell-label {
  justify-content: flex-end;
}

.ag-row-hover {
  a.table-cell-link {
    color: $color-text-link-primary;
    text-decoration: underline;
    &:not([disabled='true']):not(.disabled):hover {
      text-decoration: unset;
    }
  }
}

a.table-cell-link {
  padding: 15px 0 5px 0;
  min-height: 44px;
}

app-link-cell,
app-actions-cell {
  padding: 4px 0;
}

.ag-rich-select-row-selected {
  background-color: rgba($data-yellow, $opacity-2);
}

.ag-menu-list {
  padding: 0;
  // background-color: white;
}

.ag-root-wrapper .ag-sticky-bottom {
  display: none;
}

.ag-root-wrapper .ag-row-last {
  border-bottom: none;
}
