.aspen {
  .ps__rail-y {
    width: 6px !important;
    background: transparent !important;
  }

  .ps__thumb-y {
    width: 6px !important;
    right: 0px !important;
  }

  .ps__rail-x {
    height: 6px !important;
    background: transparent !important;
  }

  .ps__thumb-x {
    height: 6px !important;
    top: 0px !important;
  }

  .ps__thumb-x, .ps__thumb-y {
    background: map-get($colors, grey-8);
    border-radius: 4px;
  }
}
