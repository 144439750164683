.page {
  width: 100%;
  max-width: 38rem;
  margin: 2.75rem auto 0;

  a {
    color: map-get($colors, primary);
    text-decoration: none;
    word-break: break-all;

    &:hover {
      color: map-get($colors, primary-hover);
    }
  }

  @media (max-width: $screen-sm-max) {
    margin-top: 3.5rem;
  }

  &-header {
    text-align: center;
    font-weight: 300;
  }

  &-content {
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.5rem;

    p {
      margin-bottom: 3.5rem;

      @media (max-width: $screen-sm-max) {
        margin-bottom: 2.5rem;
      }
    }
  }

  &.timeout {
    .page-header {
      margin-bottom: 2.25rem;
    }

    .page-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p,
      img {
        margin-bottom: 3.5rem;

        @media (max-width: $screen-sm-max) {
          margin-bottom: 2.5rem;
        }
      }

      img {
        width: 18.5rem;
        height: auto;
      }
    }
  }
}
