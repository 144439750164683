// Below are for gen 2

@import '/node_modules/sass-rem/_rem.scss';
@import '/node_modules/@appkit4/styles/scss/variables';

body {
  --spacing-40-px: rem(40px);
  --max-content-width: 72.5rem;
}

.layout-container.has-data-table {
  --max-content-width: 100%;
}

.layout-normal-container {
  display: grid;
  grid-template-columns: var(--sidebar-width-open, var(--sidebar-width-closed)) 4fr;
  grid-template-areas: 'sidenav content';
  transition: all 0.2s ease;
}

.layout-category-container {
  display: grid;
  --margin-width: calc(
    100vw - var(--max-content-width) -
      var(--sidebar-width-open, var(--sidebar-width-closed))
  );
  grid-template-columns:
    var(--sidebar-width-open, var(--sidebar-width-closed)) calc(
      calc(var(--margin-width) * 0.5) + calc(var(--max-content-width) * 0.75)
    )
    calc(
      calc(var(--margin-width) * 0.5) + calc(var(--max-content-width) * 0.25)
    );
  // grid-template-rows: var(--header-height) 1fr;
  grid-template-areas: 'sidenav content category';
  transition: all 0.2s ease;
  overflow-y: auto;
  overflow-x: hidden;
}

.layout-category-container .category {
  grid-area: category;
  position: sticky;
  top: var(--header-height);
  padding-left: $spacing-5;
  // z-index: -1;
}

.content-wrapper {
  grid-area: content;
  background-color: $color-background-default;
  max-width: calc(
    100vw - var(--sidebar-width-open, var(--sidebar-width-closed))
  );
}

.content-wrapper-category {
  padding-right: $spacing-5;
}
.layout-normal-container .content-window,
.non-login-content-window {
  margin: auto;
  max-width: min(var(--max-content-width), 100%);
}

.category-container-section {
  position: fixed;
}

.layout-wrapper-noSideNav {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--header-height) auto;
  grid-template-areas:
    'header'
    'content';
}

.header {
  // grid-area: header;
  background-color: $color-background-default;
  position: fixed;
  // position: -webkit-sticky;
  width: 100%;
  height: var(--header-height);
  top: 0;
  z-index: 700;
}

.sidenav-container {
  display: contents;
  z-index: 98;
  background-color: $color-background-default;
  position: sticky;
  position: -webkit-sticky;
  max-height: calc(100vh - var(--header-height)) !important;
  .ap-navigation-wrapper {
    height: calc(100vh - var(--header-height)) !important;
  }
  .navigation-wrapper {
    grid-area: sidenav;
    position: fixed;
    // position: sticky;
    // position: -webkit-sticky;
    top: var(--header-height);
    height: calc(100vh - var(--header-height)) !important;
  }
}

#maincontent {
  flex: 1 0 0;
}

.content-has-header {
  max-height: calc(100vh - $gen2-header-height);
}

.footer {
  padding: $spacing-7 0 $spacing-4;
  width: 100%;
  // position: relative;
  z-index: 1;
}

.navbar-topbar-user-wrap {
  color: $color-text-heading;
  display: flex;
  flex-direction: column;
  font-size: $typography-body;
  line-height: $typography-line-height-2;
  margin-left: $spacing-4;
  box-shadow: none;
  .navbar-topbar-user-tenant {
    color: $color-text-light;
  }
  .navbar-topbar-user-name {
    color: $color-background-tertiary;
  }
}

.ap-navigation-item.selected,
.ap-navigation-item.active {
  // custom styles for the selected item
  font-weight: $font-weight-2;
  color: $color-text-primary !important;
  background-color: $color-background-hover-selected !important;
}

// .layout-container,
.layout-wrapper-pure,
.content-window {
  height: 100%;
}
.layout-container {
  min-height: calc(100% - var(--header-height));
  // margin-top: var(--header-height);
  top: var(--header-height);
  position: relative;
}
.content-wrapper {
  // min-height: 100%;
  min-height: calc(100vh - var(--header-height)); // TODO delete
  overflow: hidden;
}

.ap-option-item.avatar-dropdown-user-info {
  justify-content: unset;
  &:hover:not(.disabled) {
    background-color: unset;
  }
  cursor: unset;
}
