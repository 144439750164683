@import '/node_modules/sass-rem/_rem.scss';
@import '/node_modules/@appkit4/styles/scss/variables';

// Defining color values
$colors: (
  primary: #eb8c00,
  primary-hover: #ae6800,
  primary-select-active: #af640f,
  red: #c52919,
  orange: #ffbf1f,
  orange-2: #d39447,
  green: #22992e,
  blue: #54c5f8,
  blue-2: #415385,
  white: #ffffff,
  purple: #8e34f4,
  grey-1: #fafafa,
  grey-2: #f5f5f5,
  grey-3: #eeeeee,
  grey-4: #e0e0e0,
  grey-5: #bdbdbd,
  grey-6: #9e9e9e,
  grey-7: #757575,
  grey-8: #616161,
  grey-9: #424242,
  grey-10: #313131,
  grey-11: #212121,
  grey-12: #1a1a1a,
  grey-13: #252525,
  grey-14: #979797,
  grey-15: #696969,
  grey-16: #d1d1d1,
  grey-17: #818181,
  grey-18: #999999,
  grey-19: #474747,
  grey-20: #777777,
  black: #000000,
  azure: #4dacf1,
  dusk-blue: #225e7a,
  faded-blue: #8e34f4,
  light-moss-green: #86db4f,
  iris-two: #5c6bbf,
  tealish: #4eb523,
  appkit-blue: #037ed6,
);

// Defining size value
$font-family: 'PwC Helvetica Neue', Helvetica, Arial, 'Microsoft Yahei',
  sans-serif, 'Segoe UI Emoji', 'Emoji';
$gutter: 1.5rem;
$border-radius: 0.1875rem;
$box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.2);
$box-shadow-tooltip: 0 2px 10px 1px rgba(0, 0, 0, 0.3);
$database-scrollable-header-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
$cdk-overlay-dark-backdrop: rgba(0, 0, 0, 0.7);

// Layout size
$top-guide-banner-height: 4.75rem;
$top-guide-banner-height-md: 4.75rem;
$top-guide-banner-height-sm: 4rem;
$header-height: 5.25rem;
$header-height-md: 5.25rem;
$header-height-sm: 4.125rem;
$header-height-twoline: 6.375rem;
$header-height-twoline-md: 6.375rem;
$header-height-twoline-sm: 7.125rem;
$footer-height: 2.5rem;
$footer-height-md: 4.75rem;
$footer-height-sm: 8rem;
$sidenav-width: 6.25rem;
$sidenav-width-md: 21rem;
$sidenav-width-sm: 21rem;
$content-padding-top: 2rem;
$content-padding-bottom: 0.5rem;
$content-padding-top-md: 1.25rem;
$content-padding-bottom-md: 0.5rem;
$content-padding-top-sm: 1.375rem;
$content-padding-bottom-sm: 0.5rem;

// Defining breakpoints values
$screen-md-min: 768px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 1024px;
$screen-md-max: ($screen-lg-min - 1);

//  responsive display font-size
$min-width: 414px;
$max-width: 1440px;
$min-font: 14px;
$max-font: 16px;

$header-fixed-width: 1100px;

// Below for Gen2
$gen2-header-height: 64px;
$gen2-footerHeight: 5.5rem;
$non-table-content-width: 72.5rem;
$table-content-width: 115.625rem;
$gen2-modal-width: 33.75rem;

$spacing-30-px: rem(30px);
$spacing-2-unit: var(--spacing-2-unit);
