@import '/node_modules/sass-rem/_rem.scss';
@import '/node_modules/@appkit4/styles/scss/variables';

@each $name, $color in $colors {
  .bg-#{'' + $name} {
    background: $color !important;
  }

  .font-#{'' + $name} {
    color: $color !important;
  }
}

.app-text-light {
  // #979797 for dark
  // #696969 for light
  color: $color-text-light;
}

.app-text-body {
  // #D1D1D1 for dark
  // #474747 for light
  color: $color-text-body;
}

.app-text-heading {
  // #FFFFFF for dark
  // #252525 for light
  color: $color-text-heading;
}

.app-text-link-primary {
  color: $color-text-link-primary;
}

.app-text-secondary {
  // #FFFFFF for $neutral-01
  color: $color-text-secondary;
}

.app-text-link-secondary {
  color: $color-text-link-secondary;
}

.app-background-icon-primary {
  color: var(--color-background-icon-primary);
}

.app-text-disabled {
  color: $color-text-disabled;
}

.app-text-error {
  color: $color-text-error;
}

.app-text-success {
  color: $color-text-success;
}

.app-text-warning {
  color: $color-text-warning;
}

.app-panel-bg {
  background-color: $color-background-primary;
}

.app-card-bg {
  background-color: $primary-blue-06;
}

.app-group-bg {
  background-color: $color-background-selected;
}
