/* You can add global styles to this file, and also import other style files */

@import './sass/variables';
@import './sass/mixin';
@import './sass/normalize';
@import './sass/responsive';
@import './sass/theme';
@import './sass/colors';
@import './sass/scrollbar';
@import './sass/skeleton';
@import './sass/layout';
@import './sass/page';
@import './sass/gen2Theme';
@import '../node_modules/@appkit4/styles/appkit.min.css';
@import '../node_modules/@appkit4/styles/scss/variables';
@import './sass/agGrid';
@import './sass/mention_in_editor';

*
  :focus-visible:not(
    .ck-editor .ck.ck-editor__editable_inline,
    .ap-field-input
  ):not(.mat-mdc-input-element):not(#maincontent):not(.ap-combobox-input) {
  outline: $color-background-primary auto 1px !important;
  outline-offset: var(--spacing-1) !important;
}

/* Core Grid CSS */

@import 'ag-grid-community/styles/ag-grid.css';

/* Quartz Theme Specific CSS */

@import 'ag-grid-community/styles/ag-theme-quartz.css';

/* Alpine Theme Specific CSS */

@import 'ag-grid-community/styles/ag-theme-alpine.css';
